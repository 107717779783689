import { jsx as _jsx } from "react/jsx-runtime";
import { Navigate, useRoutes } from 'react-router-dom';
// auth
import AuthGuard from '../auth/AuthGuard';
import GuestGuard from '../auth/GuestGuard';
import RoleBasedGuard from '../auth/RoleBasedGuard';
import PasswordCheckGuard from '../auth/PasswordCheckGuard';
// layouts
import CompactLayout from '../layouts/compact';
import DashboardLayout from '../layouts/dashboard';
// config
import { PATH_AFTER_LOGIN } from '../config-global';
//
import { Dashboard, LoginPage, NewPasswordPage, Page404, ResetPasswordPage, Patient, LabPage, CabinetPage, ArchivedCabinetPage, PermissionPage, ArchivedPatientPage, OptionsPage, UpdatePassword, ProfileLabo, ProfileCabinet, Notifications, TypesPage, ProsthetistPage, OrdersPage, ArchivedOrdersPage, OrderViewOne, TreatmentViewPage, DentistPage, Calendar, DraftCommandPage, } from './elements';
import PatientForm from '../pages/PatientForm';
import { PATH_DASHBOARD } from './paths';
import ConsultCommand from '../pages/ConsultCommand';
// ----------------------------------------------------------------------
export default function Router() {
    return useRoutes([
        {
            path: '/',
            children: [
                { element: _jsx(Navigate, { to: PATH_AFTER_LOGIN, replace: true }), index: true },
                {
                    path: 'login',
                    element: (_jsx(GuestGuard, { children: _jsx(LoginPage, {}) })),
                },
                {
                    element: _jsx(CompactLayout, {}),
                    children: [
                        {
                            path: 'forget-password',
                            element: (_jsx(GuestGuard, { children: _jsx(ResetPasswordPage, {}) })),
                        },
                        {
                            path: 'reset-password/:token',
                            element: (_jsx(GuestGuard, { children: _jsx(NewPasswordPage, {}) })),
                        },
                        {
                            path: 'update-password',
                            element: (_jsx(AuthGuard, { children: _jsx(UpdatePassword, {}) })),
                        },
                    ],
                },
            ],
        },
        {
            path: '/dashboard',
            element: (_jsx(AuthGuard, { children: _jsx(PasswordCheckGuard, { children: _jsx(DashboardLayout, {}) }) })),
            children: [
                { element: _jsx(Navigate, { to: PATH_AFTER_LOGIN, replace: true }), index: true },
                {
                    path: 'app',
                    element: (_jsx(RoleBasedGuard, { roles: ['DENTIST', 'PROTHESIST', 'SUPER_ADMIN'], hasContent: true, isProfilePage: false, children: _jsx(Dashboard, {}) })),
                },
                {
                    path: 'profile-labo',
                    element: (_jsx(RoleBasedGuard, { roles: ['PROTHESIST'], hasContent: true, isProfilePage: true, children: _jsx(ProfileLabo, {}) })),
                },
                {
                    path: 'patient',
                    element: (_jsx(RoleBasedGuard, { roles: ['DENTIST', 'PROTHESIST', 'SUPER_ADMIN'], hasContent: true, children: _jsx(Patient, {}) })),
                },
                {
                    path: 'profile-cabinet',
                    element: (_jsx(RoleBasedGuard, { roles: ['DENTIST'], isProfilePage: true, hasContent: true, children: _jsx(ProfileCabinet, {}) })),
                },
                {
                    path: 'laboratoires',
                    element: (_jsx(RoleBasedGuard, { roles: ['SUPER_ADMIN'], hasContent: true, children: _jsx(LabPage, {}) })),
                },
                {
                    path: 'draft-commandes',
                    element: (_jsx(RoleBasedGuard, { roles: ['PROTHESIST', 'SUPER_ADMIN', 'DENTIST'], hasContent: true, children: _jsx(DraftCommandPage, {}) })),
                },
                {
                    path: 'treatment/:id',
                    element: (_jsx(RoleBasedGuard, { roles: ['PROTHESIST', 'SUPER_ADMIN', 'DENTIST'], hasContent: true, children: _jsx(TreatmentViewPage, {}) })),
                },
                {
                    path: 'orders',
                    element: (_jsx(RoleBasedGuard, { roles: ['PROTHESIST', 'SUPER_ADMIN', 'DENTIST'], hasContent: true, children: _jsx(OrdersPage, {}) })),
                },
                {
                    path: PATH_DASHBOARD.consult,
                    element: (_jsx(RoleBasedGuard, { roles: ['PROTHESIST', 'SUPER_ADMIN', 'DENTIST'], hasContent: true, children: _jsx(ConsultCommand, {}) })),
                },
                {
                    path: 'cabinet',
                    element: (_jsx(RoleBasedGuard, { roles: ['DENTIST', 'PROTHESIST', 'SUPER_ADMIN'], hasContent: true, children: _jsx(CabinetPage, {}) })),
                },
                {
                    path: PATH_DASHBOARD.calendar,
                    element: (_jsx(RoleBasedGuard, { roles: ['DENTIST', 'PROTHESIST', 'SUPER_ADMIN'], hasContent: true, children: _jsx(Calendar, {}) })),
                },
                { path: PATH_DASHBOARD.patientForm, element: _jsx(PatientForm, {}) },
                {
                    path: 'permission',
                    element: (_jsx(RoleBasedGuard, { roles: ['PROTHESIST', 'SUPER_ADMIN'], hasContent: true, children: _jsx(PermissionPage, {}) })),
                },
                {
                    path: PATH_DASHBOARD.archive.patient,
                    element: (_jsx(RoleBasedGuard, { roles: ['DENTIST', 'PROTHESIST', 'SUPER_ADMIN'], hasContent: true, children: _jsx(ArchivedPatientPage, {}) })),
                },
                {
                    path: PATH_DASHBOARD.archive.root,
                    children: [
                        { element: _jsx(Navigate, { to: "patient_archive", replace: true }), index: true },
                        {
                            path: PATH_DASHBOARD.archive.patient,
                            element: (_jsx(RoleBasedGuard, { roles: ['DENTIST', 'PROTHESIST', 'SUPER_ADMIN'], hasContent: true, children: _jsx(ArchivedPatientPage, {}) })),
                        },
                        {
                            path: PATH_DASHBOARD.archive.cabinet,
                            element: (_jsx(RoleBasedGuard, { roles: ['PROTHESIST', 'SUPER_ADMIN'], hasContent: true, children: _jsx(ArchivedCabinetPage, {}) })),
                        },
                        {
                            path: PATH_DASHBOARD.archive.order,
                            element: (_jsx(RoleBasedGuard, { roles: ['PROTHESIST', 'SUPER_ADMIN', 'DENTIST'], hasContent: true, children: _jsx(ArchivedOrdersPage, {}) })),
                        },
                    ],
                },
                {
                    path: PATH_DASHBOARD.order,
                    element: (_jsx(RoleBasedGuard, { roles: ['PROTHESIST', 'SUPER_ADMIN', 'DENTIST'], hasContent: true, children: _jsx(OrderViewOne, {}) })),
                },
                { path: 'patient', element: _jsx(Patient, {}) },
                { path: 'prosthetist', element: _jsx(ProsthetistPage, {}) },
                { path: 'dentist', element: _jsx(DentistPage, {}) },
                { path: 'laboratoires', element: _jsx(LabPage, {}) },
                { path: 'notifications', element: _jsx(Notifications, {}) },
                { path: 'cabinet', element: _jsx(CabinetPage, {}) },
                { path: 'permission', element: _jsx(PermissionPage, {}) },
                { path: PATH_DASHBOARD.archive.patient, element: _jsx(ArchivedPatientPage, {}) },
                { path: PATH_DASHBOARD.archive.cabinet, element: _jsx(ArchivedCabinetPage, {}) },
                {
                    path: 'config',
                    children: [
                        { element: _jsx(Navigate, { to: "options", replace: true }), index: true },
                        {
                            path: 'options',
                            element: (_jsx(RoleBasedGuard, { roles: ['SUPER_ADMIN'], hasContent: true, children: _jsx(OptionsPage, {}) })),
                        },
                        {
                            path: 'types',
                            element: (_jsx(RoleBasedGuard, { roles: ['SUPER_ADMIN'], hasContent: true, children: _jsx(TypesPage, {}) })),
                        },
                    ],
                },
            ],
        },
        {
            element: _jsx(CompactLayout, {}),
            children: [{ path: '404', element: _jsx(Page404, {}) }],
        },
        { path: '*', element: _jsx(Navigate, { to: "/404", replace: true }) },
    ]);
}
